$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';

@import "~bootstrap/scss/bootstrap";

@import "./simple_form-bootstrap/simple_form-bootstrap";
@import "~izitoast/dist/css/iziToast.min";

// palette
$lapis-color: #1f2422ff;
$white: #FFFFFF;
$ultralightgrey: #f8f9fa;
$lightgrey: #eaeef2;
$grey: #546475;
$main-active: #23c6c8;

// email states
$state-draft: #95a3b3;
$state-ready: #172832;
$state-sent: #23c6c8;

@import "./app/mixins";
@import "./app/global";
@import "./app/devise";
@import "./app/forms";
@import "./app/tables";
@import "./app/buttons";
@import "./app/documents";
@import "./app/footer";

@import './external_users/documents';

.current-external-user-box {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.25rem 1rem;
  font-size: 0.8rem;

  &:hover {
    background: $lightgrey;
  }

  a {
    margin-left: 1rem;
  }
}

.free-search-wrapper {
  position: relative;

  .reset-free-search {
    position: absolute;
    top: 1rem;
    right: 0;
    font-size: 1.5em;
    font-weight: 300;
    color: #999;
  }
}
