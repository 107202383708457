@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

$headings-font-family: 'Caudex', serif;   // 400, 600
$body-font-family: 'Outfit', sans-serif;  // 300, 400, 500, 600, 700, 800, 900

// GENERAL: ------------------------------------------------------------------------------------------------------------
$white:    #FFFFFF;
$white_alfa: rgba(#FFFFFF,.7);
$main_color: #3f4a45;

// DEVICE LIST: --------------------------------------------------------------------------------------------------------
$iphone4:             "only screen and (min-device-width: 320px) and (max-device-width:480px)";

$iphone5-port:        "only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : portrait)";

$iphone6:             "only screen and (min-device-width: 375px) and (max-device-width:413px)";
$iphone6-port:        "only screen and (min-device-width: 375px) and (max-device-width:413px) and (orientation: portrait)";
$iphone6-land:        "only screen and (min-device-width: 414px) and (max-device-width:667px) and (orientation: landscape)";

$iphone6plus:         "only screen and (min-device-width: 414px) and (max-device-width:735px)";
$iphone6plus-port:    "only screen and (min-device-width: 414px) and (max-device-width:735px) and (orientation: portrait)";
$iphone6plus-land:    "only screen and (min-device-width: 736px) and (max-device-width:768px) and (orientation: landscape)";

$iphone7:             "only screen and (min-device-width : 414px) and (max-device-width : 736px)";

$xs-sm:               "only screen and (max-width:767px)";
$sm-md:               "only screen and (min-width: 769px) and (max-width:1023px)";

$smartphone-land:     "only screen and (max-width:767px) and (orientation: landscape)";

$ipad:                "only screen and (min-device-width: 768px) and (max-device-width: 1024px)";
$ipad-port:           "only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait)";
$ipad-land:           "only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape)";

$ipadpro:             "only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)";
$ipadpro-port:        "only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5)";
$ipadpro-land:        "only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)";

$md-lg:               "only screen and (min-width: 1025px) and (max-width:1199px)";
$desktop-lowres:      "only screen and (min-width: 1200px) and (max-width:1441px)";
$desktop-mediumres:   "only screen and (min-width: 1442px) and (max-width:1800px)";
$desktop-highres:     "only screen and (min-width: 1801px)";
$desktop-h1280:       "only screen and (min-height: 1050px)"; // tiene conto delle parti esterne del browser

$desktop-1920_to_2500:     "only screen and (min-width: 1920px)";

html {
  scroll-behavior: smooth;
}
html, body {
  font-family: $body-font-family;
  font-weight: 300;
  background: $white;
  color:$black;
  letter-spacing: 1.1px;
}

h1, h2, h3 {
  font-family: $headings-font-family;
  font-weight: 500;
  letter-spacing: 1.8px;
}

h1 {
  font-size: calc(3.89rem * 0.6);   // altrimenti H1 su telefono è troppo grande
  line-height: 1.143;
  @media #{$sm-md, $md-lg, $ipad, $desktop-lowres, $desktop-mediumres, $desktop-highres} {
    font-size: 3.89rem;
    line-height: 1.143;
  }
}

h2 {
  font-size: 2.78rem;
  line-height: 1.08;
  @media #{$ipad, $desktop-lowres, $desktop-mediumres, $desktop-highres} {
    font-size: 2.78rem;
    line-height: 1.08;
  }
  @media #{$xs-sm, $sm-md, $md-lg} {
    font-size: 2rem;
    line-height: 1.08;
  }
}

h3 {
  font-size: 1.78rem;
  line-height: 1.25;
  @media #{$sm-md, $md-lg, $ipad, $desktop-lowres, $desktop-mediumres, $desktop-highres} {
    font-size: 1.78rem;
    line-height: 1.25;
  }
}

h4 {
  font-family: $body-font-family;
  font-weight: 400;
  letter-spacing: 1.8px;
  font-size: calc(2.38rem * 0.7);   // altrimenti H4 su telefono è troppo grande
  @media #{$sm-md, $md-lg, $ipad, $desktop-lowres, $desktop-mediumres, $desktop-highres} {
    font-size: 2.38rem;
    line-height: 1.25;
  }
}

h5 {
  font-family: $body-font-family;
  font-weight: 300;
  letter-spacing: 1.8px;
  font-size: calc(1.78rem * 0.7);   // altrimenti H4 su telefono è troppo grande
  @media #{$sm-md, $md-lg, $ipad, $desktop-lowres, $desktop-mediumres, $desktop-highres} {
    font-size: 1.78rem;
    line-height: 2;
  }
}

p, li {
  font-family: $body-font-family;
  font-weight: 300;
  line-height: 1.8;
  letter-spacing: 1.1px;
  @media #{$sm-md, $md-lg, $ipad, $desktop-lowres, $desktop-mediumres, $desktop-highres} {
    font-size: 1.0rem;
  }
  &.small {
    font-weight: 300;
    @media #{$sm-md, $md-lg, $ipad, $desktop-lowres, $desktop-mediumres, $desktop-highres} {
      font-size: 0.56rem;
      line-height: 1.8;
    }
  }
}

b, strong {
  font-weight:500;
}

.bg-gray {
  background-color: $lightgrey;
}

[data-bs-toggle="collapse"] {
  text-decoration: none;

  .fas {
    margin-right: 0.5em;
  }

  .fa-chevron-right { display: none; }
  .fa-chevron-down { display: initial; }

  &.collapsed {
    .fa-chevron-right { display: initial; }
    .fa-chevron-down { display: none; }
  }
}

.section-box {
  margin-bottom: 2em;

  .section-name {
    margin-bottom: 0.5em;
    font-size: 1.15em;
    font-weight: 900;
  }
}

.breadcrumb {
  margin-left: 2rem;
  margin-bottom: 2rem;
  font-size: 0.8em;
  text-align: right;

  a {
    color: $gray-600;
  }
}

.record-state-box {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0.5em 0;
  font-size: 1.25em;
  max-width: 15em;
  margin-left: auto;

  .record-current-state {
    font-weight: 600;

    &.sent {
      color: $state-sent;
      font-weight: 800;
    }
  }

  .record-state-change {
    float: right;
    margin-left: 1em;
  }
}

.key-value-pair {
  .key {
    display: block;
    font-size: 0.8em;
    color: $grey;
  }
  .value {
    display: block;
    font-weight: 600;
  }
}

.flex-auto-rows {
  div {
    display: flex;
    flex-direction: row;
    margin: 0.5rem 0;
    align-items: center;

    span {
      margin: 0 0.5rem;

      &:first-child {
        min-width: 1rem;
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
