.documents-list-wrapper {
  min-height: 24em;
}

.document-card {
  position: relative;
  font-family: $body-font-family;
  font-size: 17px;
  letter-spacing: 1.1px;
  margin: 0 auto;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  width: 1200px;
  max-width: 100%;
  user-select: none;
  padding-bottom:3rem;

  &__image {
    flex: 1;
    //width: 50%;
    padding:2rem;
  }

  &__info {
    flex: 1;
    //width: 50%;
    margin-left: 1.5em;
    padding:2rem;

    @media (max-width: 700px) {
      margin-left: 1rem;
    }
  }

  &__title {
    font-family: $headings-font-family;
    font-size: 1.5em;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 1.8px;
    margin-bottom: 1.5rem;
  }

  &__category {
    margin-bottom: 1.5rem;
  }

  &__category-name {
    display: inline-block;
    font-size: 0.75em;
    font-weight: 600;
    padding: 2px 4px;
    background: #000000;
    color: #FFFFFF;
    margin-right: 4px;
    margin-bottom: 4px;
  }

  &__document {
    position: relative;
    padding: 0.25rem 0.5rem;

    i.fas {
      visibility: hidden;
      font-size: 0.8em;
      margin-right: 0.5rem;
    }

    a {
      color: #000;
      text-decoration: none;
    }

    &-size {
      color: #999;
      font-style: italic;
      white-space: nowrap;
    }

    &:hover {
      background-color: #f2f2f2;

      i.fas {
        visibility: visible;
      }
    }
  }

  &__show-more-documents {
    position: relative;
    padding: 0.25rem 0.5rem;
    color: #999;
    font-style: italic;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    width: auto;
    padding: 0;
    font-size: 14px;

    .document-card__image {
      padding: 0;
    }

    .document-card__info {
      padding: 0;
      margin: 1.5rem 0 4rem 0;
    }
  }
}
