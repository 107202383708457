body.devise {
  .devise-body {
    padding: 2em;
    background: $lapis-color;
    color: $white;
    min-height: 75vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.files {
      min-height: 100vh;
    }


    .devise-title {
      font-weight: 400;
    }

    .lapis-logo {
      margin-bottom: 3em;
    }


    .devise-wrapper {
      width: 540px;
      max-width: 100%;
    }

    input::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.3);
    }
    input:focus::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.3);
    }
    /* Firefox < 19 */
    input:-moz-placeholder {
      color: rgba(255, 255, 255, 0.3);
      color: black;
    }
    input:focus:-moz-placeholder {
      color: rgba(255, 255, 255, 0.3);
    }

    /* Firefox > 19 */
    input::-moz-placeholder {
      color: rgba(255, 255, 255, 0.3);
    }
    input:focus::-moz-placeholder {
      color: rgba(255, 255, 255, 0.9);
    }

    /* Internet Explorer 10 */
    input:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.3);
    }
    input:focus:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.3);
    }

    .invalid-feedback {
      color: rgba(255, 255, 255, 0.8);
    }

    input.form-control {
      width: 100%;
      border:0;
      border-bottom:1px dashed #FFFFFFAA;
      box-shadow: 1px 1px 2px rgba(0,0,0,0.02);
      padding: 0em 0.02em;
      font-size:1.2em;
      margin: 1.5em 0 0 0;
      height: 2.6em;
      color:#fff;
      text-align: center;
      background-color: transparent;
      border-bottom:1px dashed #FFFFFF;
    }

    .btn-devise {
      display: block;
      width: 100%;
      margin: 3em auto;
      text-transform: uppercase;
      color: #FFFFFF;
      border-color: rgba(255,255,255, .6);
      border-radius: 0;
      padding: 1em;
      letter-spacing: 1.2px;
      &:hover{
        background-color: lighten($lapis-color, 10);
        //border-color: lighten($lapis-color, 15);
      }
      &.reset-password{
        max-width:350px;
        display: block;
        margin-bottom: 20px;
        margin-top: 30px;
      }
    }

    a, .form-check {
      color:#FFFFFF;
      text-shadow: 1px 1px 1px rgba(255,255,255,0.1   );
      font-size: 14px;
      &:hover{
        color:rgba(255,255,255,0.7);

      }
    }

    a {
      text-decoration: none;
    }

  }

  .devise-footer {
    min-height: 25vh;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    padding: 1em;

    > div {
      padding: 1em;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: 0;

      > div {
        width: 100%;
        flex: 1;
        text-align: center;
      }
    }

  }

}
