.btn-rounded {
  line-height: 1.5;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.2), 0 1px 3px 1px rgba(60,64,67,0.1);
  -webkit-font-smoothing: antialiased;
  align-items: center;
  background-image: none;
  border-radius: 24px;
  font-weight: 500;
  text-transform: none;
  padding:6px 16px;
  i:not(.icon-only) {
    padding-right: 1em;
  }
}
