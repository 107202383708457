@mixin svg_filters($FinalColor) {
  //https://codepen.io/sosuke/pen/Pjoqqp
  @if $FinalColor == $white {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(295deg) brightness(104%) contrast(102%); //black to $white
  } @else {

  }
}

@mixin ellipsis-text($font-size, $line-height, $lines-to-show) {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  //max-height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
