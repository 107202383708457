#footer {
  font-family: $body-font-family;

  h1, h2, h3 {
    font-family: $headings-font-family;
  }

  background: $main_color;
  padding: 6rem 4rem 6rem 4rem;
  @media #{$xs-sm} {
    padding: 0 0 0 0;
    text-align: center;
  }
  @media #{$sm-md, $md-lg, $ipad} {
    padding: 6rem 1rem 6rem 1rem;
  }
  h4 {
    color: $white_alfa;
  }
  p {
    color: $white_alfa;
    @media #{$sm-md, $md-lg, $desktop-lowres, $desktop-mediumres, $desktop-highres} {
      margin: 2rem 0 0 0;
      padding: 0;
    }
    @media #{$ipad-port} {
      font-size: 10px;
    }
    b {
      color: $white_alfa;
      font-weight: 400;
    }
    &:nth-of-type(1) {
      @media #{$sm-md, $md-lg, $desktop-lowres, $desktop-mediumres, $desktop-highres} {
        margin: 0 0 0 0;
      }
    }
    a {
      color: $white_alfa;
      &:hover {
        color: $white_alfa;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 0.25rem;
      }
    }
  }
  a {
    text-decoration: none;
  }
  .company_name_and_address {
    @media #{$xs-sm} {
      margin: 3rem auto 0 auto;
    }
  }
  .phone_fax_and_emails_wrapper {
    @media #{$ipad, $sm-md, $md-lg, $desktop-lowres, $desktop-mediumres, $desktop-highres} {
      margin: 0 0 0 0;
      padding: 0 0 0 2rem;
    }
  }
  .logo_and_socials_wrapper {
    margin: 2rem 0 0 0;
    @media #{$ipad, $sm-md, $md-lg, $desktop-lowres, $desktop-mediumres, $desktop-highres} {
      margin: 0;
    }
    .logo_container {
      display: block;
      width: 75%;
      @media #{$xs-sm} {
        margin: 0 auto 1.5rem auto;
      }
      @media #{$ipad, $sm-md, $md-lg, $desktop-lowres, $desktop-mediumres, $desktop-highres} {
        margin: 0 0 4rem auto;
      }
      @media #{$ipad} {
        width: 100%;
      }
      img {
        @media #{$ipad, $sm-md, $md-lg, $desktop-lowres, $desktop-mediumres, $desktop-highres} {
          height: 2.5rem;
          opacity: 0.7;
        }
        @include svg_filters($white);
      }
    }
    .socials_container {
      margin: 2em 0;
      @media #{$ipad, $sm-md, $md-lg, $ipad, $desktop-lowres, $desktop-mediumres, $desktop-highres} {
        margin: 0.5rem auto 0 auto;
        padding: 0;
        display: inline-block;
      }

      ul {
        opacity: 0.7;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin: 0 1em 0 0;
          display: inline-block;
          width: inherit;
          @media #{$desktop-lowres, $desktop-mediumres, $desktop-highres} {
            margin: 0 2em 0 0;
          }

          a {
            img {
              width: 32px;
              height: 32px;
              @include svg_filters($white);
            }

            &:hover {
              color: $white_alfa;

              img {
                @include svg_filters($white);
              }
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.closing {
  background: $main_color;
  padding: 1.5rem 4rem;
  position:relative;
  display: block;
  z-index: 2;
  @media #{$xs-sm} {
    padding: 1rem 0;
    //margin: 3rem 0 0 0;
  }
  @media #{$sm-md, $md-lg, $ipad} {
    padding: 1.5rem 1rem 1.5rem 1rem;
  }
  p {
    //font-size: 0.85rem;
    color: $white_alfa;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    a {
      color: $white_alfa;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 0.125rem;
      }
    }
  }
}
