$grid-card-width: 290px;
$grid-card-height: 360px;
$grid-card-bg: #FFFFFF;
$grid-card-color: #0A2538;
$grid-card-gap: 30px;
$grid-card-border-color: #D0DAE1;
$grid-card-shadow-color: $grid-card-border-color;
$grid-card-border-radius: 10px;

.documents-list {
  min-height: 330px;
}

.file-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 6rem 3rem;

  @media (max-width: 1440px) {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}

.grid-item {
  padding: 30px;
}
