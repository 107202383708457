.table {

  th, td {

    .single-line {
      @include ellipsis-text(1em, 2, 1);
      padding-right: 1rem;
    }

    &.action-details{
      position: relative;
      width: 6em;
      text-align: center;

      i{
        color: $black;
      }
      &:hover{
        background-color: $black;
        i{
          color: #FFF;
        }
      }
    }

    &.td-5 { width: 5em; }
    &.td-10 { width: 10em; }
    &.td-15 { width: 15em; }
    &.td-20 { width: 20em; }
    &.td-25 { width: 25em; }
    &.td-30 { width: 30em; }
  }

}

.table-key-value {

  tr {

    td[colspan="2"] {
      text-align: center;
    }

    td:nth-child(2) {
      font-weight: 600;
    }

  }

}
