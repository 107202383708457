.search-form{
  ::-webkit-input-placeholder {
    font-style: italic;
    color: $lightgrey;
  }

  :-moz-placeholder { /* Firefox 18- */
    font-style: italic;
    color: $lightgrey;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    font-style: italic;
    color: $lightgrey;
  }

  :-ms-input-placeholder {
    font-style: italic;
    color: $lightgrey;
  }

  .fa-search {
    margin-left: 0.25em;
  }

  .free-search {
    border: 1px solid transparent;
    padding: 0.25em 1em;
    background: $ultralightgrey;
    border-radius: 50px;

    &:focus {
      border: 1px solid #e0e1e2;
      background: $ultralightgrey;
      outline-color: rgba(0, 0, 0, 0.87);
      outline-style: none;
      outline-width: 0;
    }

    .form-group{
      display: inline-block;
      input.string{
        width: 20em;
      }
    }
  }

  .form-control-remote {
    border: 1px solid transparent;
  }

  .show-disabled-filter {
    font-size: 0.8em;
  }
}

.form-actions-sticky {
  position: sticky;
  top: 4em;
}

.check_filters {
  text-align: right;
  .form-check .form-check-input {
    float: none;
    margin-right: 0.5rem;
  }
}

.high-rich-text {
  min-height: 15em;
}

// Select2
.select2-container .select2-selection--single {
  height: 38px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 38px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 38px;
}

.checkbox-filter {
  border: none;
  position: relative;
  background: #F3F4F3;
  display: inline-block;
  padding: 0.5em 2em;
  margin: 10px;
  color: #000000;
  font-size: 0.75em;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }

  &.pressed {
    background: #000000;
    color: #FFFFFF;

    &:before {
      content: ' ';
      display: inline-block;
      position: absolute;
      top: 0.55rem;
      left: 11px;
      width: 14px;
      height: 14px;
      background: url('../../images/documents/times.svg') no-repeat 0 0/0.5rem;
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(295deg) brightness(104%) contrast(102%);
    }
  }
}


.slim-inputs {

  input::-webkit-input-placeholder {
    color: rgba($black, 0.3);
  }
  input:focus::-webkit-input-placeholder {
    color: rgba($black, 0.3);
  }
  /* Firefox < 19 */
  input:-moz-placeholder {
    color: rgba($black, 0.3);
    color: black;
  }
  input:focus:-moz-placeholder {
    color: rgba($black, 0.3);
  }

  /* Firefox > 19 */
  input::-moz-placeholder {
    color: rgba($black, 0.3);
  }
  input:focus::-moz-placeholder {
    color: rgba($black, 0.9);
  }

  /* Internet Explorer 10 */
  input:-ms-input-placeholder {
    color: rgba($black, 0.3);
  }
  input:focus:-ms-input-placeholder {
    color: rgba($black, 0.3);
  }

  .invalid-feedback {
    color: rgba($black, 0.8);
  }

  input.form-control {
    width: 100%;
    border:0;
    border-bottom:1px dashed #000000AA;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.02);
    padding: 0em 0.02em;
    font-size:1.2em;
    margin: 1.5em 0 0 0;
    height: 2.6em;
    color:$black;
    text-align: center;
    background-color: transparent;
    border-bottom:1px dashed rgba($black, 0.3);
  }
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
